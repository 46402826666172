#stilizirani-div {
    /* Dodajte ovdje vaše stilove */
    font-size: 16px;
    color: #333;
    /* itd. */
  }

  #stilizirani-div img {
    /* Dodajte ovdje vaše stilove za slike unutar #stilizirani-div */
    max-width: 100%;
    max-height: 600px; /* Ovo će osigurati da slike ne prelaze širinu roditeljskog diva */
    border-radius: 8px; /* Primjer za dodavanje zaobljenih rubova slikama */
    /* Dodajte druge stilove prema potrebi */
  }